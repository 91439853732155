/* @import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap"); */

@tailwind base;
@tailwind components;
@tailwind utilities;

/* third-party libraries CSS */

#table-of-contents ol {
  list-style: auto;
  margin-left: 20px;
  counter-reset: item
}

#table-of-contents ol li {
  --tw-text-opacity: 1;
  color: rgb(45 104 248 / var(--tw-text-opacity));
  font-weight: 700;
  font-size: 0.875rem;
  line-height: 1.25rem;
  padding: 4px 0px;
  counter-increment: item;
}

#table-of-contents ol li a:hover {
  text-decoration: underline;
}

#table-of-contents ol ol > li {
  display: block;
}

#table-of-contents ol ol li a:hover {
  text-decoration: underline;
}

#table-of-contents ol ol > li:before {
  content: counters(item, ".") ". ";
  margin-left: -20px;
}

/* clears the ‘X’ from Internet Explorer */
input[type="search"]::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}
input[type="search"]::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}
/* clears the ‘X’ from Chrome */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}

#supportCheckbox:checked ~ div span {
  @apply opacity-100;
}

@layer base {
  html {
    @apply scroll-smooth;
  }

  body {
    @apply font-inter font-normal text-base text-dark-4 relative z-1;
  }
}

@layer components {
  .dropdown {
    @apply flex-col gap-1 min-w-max lg:w-[230px] mt-2 lg:mt-0 bg-white shadow-dropdown ease-in duration-200 p-3 rounded-lg left-0 hidden
    lg:translate-y-10 lg:opacity-0 lg:invisible lg:absolute lg:flex
    lg:group-hover:translate-y-3 lg:group-hover:opacity-100 lg:group-hover:visible;
  }
  .img-rounded img {
    @apply rounded-lg
  }
}

@layer utilities {
  /* Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .gradient {
    background-image: linear-gradient(
      90deg,
      rgba(229, 231, 235, 0) 0%,
      #e5e7eb 50.52%,
      rgba(229, 231, 235, 0) 100%
    );
  }
}